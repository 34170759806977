import React from 'react';
import '../styles/index.css';
import MainPage from "./MainPage";
import Seo from "../components/Seo";

const IndexPage = () => (
    <MainPage>
        <Seo title="Bongard Россия" description="Хлебопекарное оборудование Bongard"/>
    </MainPage>
)
export default IndexPage
